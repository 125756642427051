/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  Grid,
  Typography,
  RACTextbox,
  RACDatePicker,
  RACButton,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { headerStyles } from '../../headerStyles';
import { fieldDeviceStyles } from '../../fieldDeviceSty';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SearchImageDefault } from '../../assets/images/search-btn.svg';
import moment from 'moment';
export const GlobalSearch = (props: any) => {
  const classes = headerStyles();
  const classes1 = fieldDeviceStyles();
  const history = useHistory();
  const searchCriteriaObject = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    ssn1: '',
    ssn2: '',
    ssn3: '',
    phoneNumber: '',
  };
  const [searchCriteria, setSearchCriteria] = useState(searchCriteriaObject);
  const [disableSearchBtn, setDisableSearchBtn] = useState(true);
  const [datesetting, setDatesetting] = useState('');
  const [minDate, setMinDate] = useState('');

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('props.hideShowForm in gbl', props.hideShowForm);
  }, [props.hideShowForm]);

  useEffect(() => {
    if (
      searchCriteria.firstName.length + searchCriteria.lastName.length >= 3 ||
      searchCriteria.dateOfBirth ||
      searchCriteria.phoneNumber.length == 14 ||
      searchCriteria.ssn1.length +
        searchCriteria.ssn2.length +
        searchCriteria.ssn3.length ==
        9
    ) {
      setDisableSearchBtn(false);
    } else {
      setDisableSearchBtn(true);
    }
  }, [searchCriteria]);

  useEffect(() => {
    setDatesetting(moment().subtract(18, 'years').format('YYYY-MM-DD'));
    setMinDate(moment().subtract(110, 'years').format('YYYY-MM-DD'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchHandler = (e: any, src: any) => {
    if (src == 'NAME') {
      setSearchCriteria({
        ...searchCriteria,
        [e.target.name]: e.target.value.replace(/[^A-Z-',.; a-z]/gi, ''),
      });
    } else if (src == 'DOB') {
      setSearchCriteria({ ...searchCriteria, dateOfBirth: e });
    } else if (src == 'PHONE') {
      setSearchCriteria({ ...searchCriteria, [e.target.name]: e.target.value });
    } else if (src == 'SSN1') {
      setSearchCriteria({
        ...searchCriteria,
        [e.target.name]: e.target.value.replace(/[^0-9\b]/gi, ''),
      });
      const ssn2El: any = document.getElementById('a11y_ssn2');
      const ssn1El: any = document.getElementById('a11y_ssn1');
      if (
        e.target.value.replace(/[^0-9\b]/gi, '').length === 3 &&
        ssn1El == document.activeElement
      ) {
        ssn2El.focus();
      }
    } else if (src == 'SSN2') {
      setSearchCriteria({
        ...searchCriteria,
        [e.target.name]: e.target.value.replace(/[^0-9\b]/gi, ''),
      });
      const ssn3El: any = document.getElementById('a11y_ssn3');
      const ssn2El: any = document.getElementById('a11y_ssn2');
      const ssn1El: any = document.getElementById('a11y_ssn1');
      if (
        e.target.value.replace(/[^0-9\b]/gi, '').length == 2 &&
        ssn2El == document.activeElement
      ) {
        ssn3El.focus();
      }
      if (
        e.target.value.replace(/[^0-9\b]/gi, '').length == 0 &&
        searchCriteria.ssn1.length > 0 &&
        ssn2El == document.activeElement
      ) {
        ssn1El.focus();
      }
    } else if (src == 'SSN3') {
      setSearchCriteria({
        ...searchCriteria,
        [e.target.name]: e.target.value.replace(/[^0-9\b]/gi, ''),
      });
      const ssn3El: any = document.getElementById('a11y_ssn3');
      const ssn2El: any = document.getElementById('a11y_ssn2');
      if (
        e.target.value.replace(/[^0-9\b]/gi, '').length == 0 &&
        searchCriteria.ssn2.length > 0 &&
        ssn3El == document.activeElement
      ) {
        ssn2El.focus();
      }
    }
  };

  const invokeRedirection = () => {
    clickedOutside();
    history.push({
      pathname: '/customer/searchcustomer',
      state: {
        FirstName: searchCriteria.firstName,
        LastName: searchCriteria.lastName,
        DOB: searchCriteria.dateOfBirth,
        PhoneNumber: searchCriteria.phoneNumber.replace(/\D/g, ''),
        SSN1: searchCriteria.ssn1,
        SSN2: searchCriteria.ssn2,
        SSN3: searchCriteria.ssn3,
      },
    });
  };

  const clickedOutside = () => {
    // eslint-disable-next-line no-console
    console.log('Reached the outside click');
    setSearchCriteria(searchCriteriaObject);
    props.searchOutsideClick();
  };

  const enterEncountered = (e: any) => {
    if (e.key === 'Enter' && !disableSearchBtn) {
      invokeRedirection();
    }
  };
  return (
    <OutsideClickHandler onOutsideClick={clickedOutside}>
      <Grid
        data-testid="searchbtn"
        className={clsx({
          [classes.racGlobalSearchShow]: props.hideShowForm === 'show',
        })}
        classes={{
          root: clsx(classes.racDropdownMenu, classes.racMenuContent),
        }}
        aria-labelledby="navbarglobalsearch"
      >
        <Grid container spacing={2} className={` ${classes1.p3}`}>
          <Grid item lg="auto" md={4} sm={6}>
            {/* <label htmlFor="a11y_fn" className="form-label">
                        First Name
                      </label> */}
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Last Name
            </Typography>
            <Grid className={classes.formInputAlign}>
              <RACTextbox
                maxlength={30}
                isCurrency={false}
                id="lastnametextbox"
                type="text"
                name="lastName"
                OnKeydown={(e) => enterEncountered(e)}
                value={searchCriteria.lastName}
                OnChange={(event) => searchHandler(event, 'NAME')}
              ></RACTextbox>
            </Grid>
          </Grid>
          <Grid item lg="auto" md={4} sm={6}>
            {/* <label htmlFor="a11y_ln" className="form-label">
                        Last Name
                      </label> */}
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
              // eslint-disable-next-line sonarjs/no-duplicate-string
            >
              First Name
            </Typography>
            <Grid className={classes.formInputAlign}>
              <RACTextbox
                isCurrency={false}
                maxlength={30}
                type="text"
                value={searchCriteria.firstName}
                name="firstName"
                OnKeydown={(e) => enterEncountered(e)}
                OnChange={(event) => searchHandler(event, 'NAME')}
              ></RACTextbox>
            </Grid>
          </Grid>
          <Grid item lg="auto" md={4} sm={6}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Date of Birth
            </Typography>
            <Grid className={classes.formInputAlignDate}>
              <RACDatePicker
                label=""
                value={searchCriteria.dateOfBirth}
                onChange={(event: any) => searchHandler(event, 'DOB')}
                inputProps={{ max: datesetting, min: minDate }}
              ></RACDatePicker>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Social Security# / ITIN#
            </Typography>
            <Grid container spacing={2} className={classes.formInputAlign}>
              <Grid item md={4} lg={4} sm={4} className={classes.PT0PB0}>
                <RACTextbox
                  maxlength={3}
                  isCurrency={false}
                  type="password"
                  value={searchCriteria.ssn1}
                  name="ssn1"
                  className={classes.textCenterSSN}
                  OnChange={(event) => searchHandler(event, 'SSN1')}
                  id="a11y_ssn1"
                ></RACTextbox>
              </Grid>
              <Grid item md={3} lg={3} sm={3} className={classes.PT0PB0}>
                <RACTextbox
                  maxlength={2}
                  isCurrency={false}
                  type="password"
                  value={searchCriteria.ssn2}
                  name="ssn2"
                  OnChange={(event) => searchHandler(event, 'SSN2')}
                  className={classes.textCenterSSN}
                  id="a11y_ssn2"
                ></RACTextbox>
              </Grid>
              <Grid item md={5} lg={5} sm={5} className={classes.PT0PB0}>
                <RACTextbox
                  maxlength={4}
                  isCurrency={false}
                  type="text"
                  name="ssn3"
                  OnKeydown={(e) => enterEncountered(e)}
                  value={searchCriteria.ssn3}
                  OnChange={(event) => searchHandler(event, 'SSN3')}
                  className={classes.textCenterSSN}
                  id="a11y_ssn3"
                ></RACTextbox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg="auto" md={4} sm={6}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Phone Number
            </Typography>
            <Grid className={classes.formInputAlign}>
              <RACTextbox
                isCurrency={false}
                type="phoneno"
                name="phoneNumber"
                OnKeydown={(e) => enterEncountered(e)}
                value={searchCriteria.phoneNumber}
                maxlength={14}
                OnChange={(event) => searchHandler(event, 'PHONE')}
                pattern="\D*"
              ></RACTextbox>
            </Grid>
          </Grid>
          <Grid item lg="auto" md={4} sm={6}>
            <RACButton
              startIcon={<SearchImageDefault />}
              disabled={disableSearchBtn}
              variant="contained"
              color="primary"
              size="small"
              className={classes.racSearchPrimary}
              onClick={invokeRedirection}
            >
              Search
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    </OutsideClickHandler>
  );
};
