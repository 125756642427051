/* eslint-disable prettier/prettier */
import { sanitizeURL } from "../utils/utils";

const ANALYTICS_DEV_ID = 'G-1Y8N3GH74F';
const ANALYTICS_PROD_ID = 'G-1Y8N3GH74F';
export interface ApiUrls {
  readonly container: string;
  readonly micro: string;
  readonly customers?: string;
  readonly agreement: string;
}

export interface AppConfigItem {
  readonly apiUrls: ApiUrls;
  readonly microUrl: string;
  readonly auth: {
    readonly region: string;
    readonly userPoolId: string;
    readonly userPoolWebClientId: string;
    readonly oauth: {
      readonly domain: string;
      readonly scope?: string[];
      readonly redirectSignIn: string;
      readonly redirectSignOut: string;
      readonly responseType: string;
      readonly urlOpener: (url: string) => void;
    };
  };
  readonly microfrontends: {
    readonly dashboard: {
      readonly name: string;
      readonly url: string;
    };
    readonly manualPO: {
      readonly name: string;
      readonly url: string;
    };
    readonly configurations: {
      readonly name: string;
      readonly url: string;
    };
    readonly payment: {
      readonly name: string;
      readonly url: string;
    };
    readonly payment1: {
      readonly name: string;
      readonly url: string;
    };
    readonly agreement: {
      readonly name: string;
      readonly url: string;
    };
    readonly accountManagement: {
      readonly name: string;
      readonly url: string;
    };
    readonly dailyActivityPlanner: {
      readonly name: string;
      readonly url: string;
    };
    readonly inventory: {
      readonly name: string;
      readonly url: string;
    };
    readonly reports: {
      readonly name: string;
      readonly url: string;
    };
    readonly reporting: {
      readonly name: string;
      readonly url: string;
    };
    readonly store: {
      readonly name: string;
      readonly url: string;
    };
    readonly customer: {
      readonly name: string;
      readonly url: string;
    };
    readonly pricing: {
      readonly name: string;
      readonly url: string;
    };
    readonly receivingPO: {
      readonly name: string;
      readonly url: string;
    };
    readonly cashMgmt: {
      readonly name: string;
      readonly url: string;
    };
    readonly mpo: {
      readonly name: string;
      readonly url: string;
    };
    readonly acimapo: {
      readonly name: string;
      readonly url: string;
    };
    readonly opsMgmt: {
      readonly name: string;
      readonly url: string;
    };
    readonly taxMgmt: {
      readonly name: string;
      readonly url: string;
    }
    readonly finance: {
      readonly name: string;
      readonly url: string;
    }
  };
  readonly googleAnalytics: {
    trackingId: string,
    options: {
      debug?: boolean,
      gaOptions?: {
        cookieDomain: string
      }
    }
  }
}

export const getMicrofrontendSubdomain = (origin: string) => {
  if (typeof origin !== "string") {
    throw new Error("Incorrect origin!");
  }
  if (origin.includes("//localhost")) return "localhost-";
  if (origin.includes("//local")) return "local-";
  if (origin.includes("//dev")) return "dev-";
  if (origin.includes("//qa")) return "qa-";
  if (origin.includes("//uat")) return "uat-";

  // prod
  return "";
};

export const getMicrofrontendsConfigs = (origin: string) => {
  const microfrontendSubdomain = getMicrofrontendSubdomain(origin);

  return {
    manualPO: {
      name: "ManualPO",
      url: `https://${microfrontendSubdomain}manual-po-racpad.rentacenter.com/manual-po`,
    },
    receivingPO: {
      name: "ReceivingPO",
      url: `https://${microfrontendSubdomain}receivingpo-racpad.rentacenter.com/receivingpo`,
    },
    configurations: {
      name: "Configurations",
      url: `https://${microfrontendSubdomain}configs-racpad.rentacenter.com/configs`,
    },
    payment: {
      name: "Payment",
      url: `https://${microfrontendSubdomain}altam-racpad.rentacenter.com/payment`,
    },
    payment1: {
      name: "Payment1",
      url: `https://${microfrontendSubdomain}payment1-racpad.rentacenter.com/payment1`,
    },

    accountManagement: {
      name: "AccountManagement",
      url: `https://${microfrontendSubdomain}am-racpad.rentacenter.com/am`,
    },
    dailyActivityPlanner: {
      name: "DailyActivityPlanner",
      url: `https://${microfrontendSubdomain}dap-racpad.rentacenter.com/dap`,
    },
    reports: {
      name: "Reports",
      url: `https://${microfrontendSubdomain}reports-racpad.rentacenter.com/reports`,
    },
    reporting: {
      name: "Reporting",
      url: `https://${microfrontendSubdomain}reporting-racpad.rentacenter.com/reporting`,
    },
    store: {
      name: "storemgmt",
      url: `https://${microfrontendSubdomain}storemgmt-racpad.rentacenter.com/storemgmt`,
    },
    dashboard: {
      name: "Dashboard",
      url: `https://${microfrontendSubdomain}dashboard-racpad.rentacenter.com/dashboard`,
    },
    inventory: {
      name: "Inventory",
      url: `https://${microfrontendSubdomain}inventory-racpad.rentacenter.com/inventory`,
    },
    agreement: {
      name: "Agreement",
      url: `https://${microfrontendSubdomain}agreement-racpad.rentacenter.com/agreement`,
    },
    customer: {
      name: "Customer",
      url: `https://${microfrontendSubdomain}customer-racpad.rentacenter.com/customer`,
    },
    pricing: {
      name: "Pricing",
      url: `https://${microfrontendSubdomain}pricing-racpad.rentacenter.com/pricing`,
    },
    cashMgmt: {
      name: "CashMgmt",
      url: `https://${microfrontendSubdomain}cashmgmt-racpad.rentacenter.com/cashmgmt`,
    },
    mpo: {
      name: "ManualPO",
      url: `https://${microfrontendSubdomain}mpo-racpad.rentacenter.com/cashmgmt`,
    },
    acimapo: {
      name: "AcimaPo",
      url: `https://${microfrontendSubdomain}acima-po-racpad.rentacenter.com/acima-po`,
    },
    opsMgmt: {
      name: "OpsMgmt",
      url: `https://${microfrontendSubdomain}opsmgmt-racpad.rentacenter.com/operations`,
    },
    taxMgmt: {
      name: "taxMgmt",
      url: `https://${microfrontendSubdomain}taxmgmt-racpad.rentacenter.com/taxmgmt`,
    },
    finance: {
      name: "finance",
      url: `https://${microfrontendSubdomain}finance-racpad.rentacenter.com/finance`,
    }
    // pricing: {
    //   name: 'Pricing',
    //   url: `https://${getMicrofrontendSubdomain(
    //     origin
    //   )}pricing-racpad.rentacenter.com/pricing`,
    // },
  };
};

const microUrl = {
  localhost: "http://localhost:3006",
  local: "https://local-menu-racpad.rentacenter.com",
  dev: "https://dev-menu-racpad.rentacenter.com",
  qa: "https://qa-menu-racpad.rentacenter.com",
  uat: "https://uat-menu-racpad.rentacenter.com",
  prod: "https://racpad-menu.rentacenter.com",
};

const microfrontends_values = {
  manualPO: {
    name: "ManualPO",
    url: "_manualpo_app_",
  },
  receivingPO: {
    name: "ReceivingPO",
    url: "_receivingpo_app_",
  },
  configurations: {
    name: "Configurations",
    url: "_configurations_app_",
  },
  payment: {
    name: "Payment",
    url: "_payment_app_",
  },
  payment1: {
    name: "Payment1",
    url: "_payment1_app_",
  },

  accountManagement: {
    name: "AccountManagement",
    url: "_am_app_",
  },
  dailyActivityPlanner: {
    name: "DailyActivityPlanner",
    url: "_dap_app_",
  },
  reports: {
    name: "Reports",
    url: "_reports_app_",
  },
  reporting: {
    name: "Reporting",
    url: "_reporting_app_",
  },
  store: {
    name: "storemgmt",
    url: "_store_app_",
  },
  dashboard: {
    name: "Dashboard",
    url: "_dashboard_app_",
  },
  inventory: {
    name: "Inventory",
    url: `_inventory_app_`,
  },
  agreement: {
    name: "Agreement",
    url: "_agreement_app_",
  },
  customer: {
    name: "Customer",
    url: "_customer_app_",
  },
  pricing: {
    name: "Pricing",
    url: "_pricing_app_",
  },
  cashMgmt: {
    name: "CashMgmt",
    url: "_cashmgmt_app_",
  },
  mpo: {
    name: "ManualPO",
    url: "_mpo_app_",
  },
  acimapo: {
    name: "AcimaPo",
    url: "_acimapo_app_",
  },
  opsMgmt: {
    name: "OpsMgmt",
    url: "_opsmgmt_app_",
  },
  taxMgmt: {
    name: "taxMgmt",
    url: "_taxmgmt_app_",
  },
  finance: {
    name: "finance",
    url: "_finance_app_",
  },
};
export interface AppConfig extends AppConfigItem {
  readonly appName: string;
}

export const urlOpener = (url: string): void => {
  const urlToOpen = sanitizeURL(url);

  if (urlToOpen) {
    window.open(urlToOpen, "_self");
  }
};

export const localhost: AppConfigItem = {
  apiUrls: {
    container: "https://local-racpad.rentacenter.com/api",
    micro: "https://local-menu-racpad.rentacenter.com/api",
    customers: "https://local-customers-racpad.rentacenter.com/api",
    agreement: "https://local-agreement-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.localhost,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_FPWut7aB",
    userPoolWebClientId: "608s8dso4aecka8omjpukssq3n",
    oauth: {
      domain: "auth-local-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.localhost,
      redirectSignOut: microUrl.localhost,
      responseType: "token",
      urlOpener,
    },
  },
  microfrontends: {
    manualPO: {
      name: "ManualPO",
      url: "http://localhost:3001/manual-po",
    },
    configurations: {
      name: "Configurations",
      url: "http://localhost:3002/configs",
    },
    payment: {
      name: "Payment",
      url: "http://localhost:3003/payment",
    },
    payment1: {
      name: "Payment",
      url: "http://localhost:3003/payment1",
    },
    accountManagement: {
      name: "AccountManagement",
      url: "http://localhost:3008/am",
    },
    dailyActivityPlanner: {
      name: "DailyActivityPlanner",
      url: "http://localhost:3006/dap",
    },
    reports: {
      name: "Reports",
      url: "http://localhost:3005/reports",
    },
    reporting: {
      name: "Reports",
      url: "http://localhost:3005/reporting",
    },
    store: {
      name: "Store",
      url: "http://localhost:3012/storemgmt",
    },
    dashboard: {
      name: "Dashboard",
      url: "http://localhost:3014/dashboard",
    },
    inventory: {
      name: "Inventory",
      url: "http://localhost:3009/inventory",
    },
    agreement: {
      name: "Agreement",
      url: "http://localhost:3010/agreement",
    },
    customer: {
      name: "Customer",
      url: "http://localhost:3010/customer",
    },
    pricing: {
      name: "Pricing",
      url: "http://localhost:3011/pricing",
    },
    receivingPO: {
      name: "ReceivingPO",
      url: "http://localhost:3007/receivingpo",
    },
    cashMgmt: {
      name: "cashMgmt",
      url: "http://localhost:3007/cashMgmt",
    },
    mpo: {
      name: "ManualPO",
      url: "http://localhost:3007/mpo",
    },
    acimapo: {
      name: "AcimaPo",
      url: "http://localhost:3012/acimapo",
    },
    opsMgmt: {
      name: "OpsMgmt",
      url: "http://localhost:3000/operations",
    },
    taxMgmt: {
      name: "taxMgmt",
      url: "http://localhost:3000/tax",
    },
    finance: {
      name: "finance",
      url: "http://localhost:3000/finance",
    }
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {
      debug: true,
      gaOptions: {
        cookieDomain: 'none'
      }
    }
  }
};

export const localhostIE11: AppConfigItem = { ...localhost };

export const local: AppConfigItem = {
  apiUrls: {
    container: "https://local-racpad.rentacenter.com/api",
    micro: "https://local-menu-racpad.rentacenter.com/api",
    customers: "https://local-customers-racpad.rentacenter.com/api",
    agreement: "https://local-agreement-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.local,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_FPWut7aB",
    userPoolWebClientId: "608s8dso4aecka8omjpukssq3n",
    oauth: {
      domain: "auth-local-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.local,
      redirectSignOut: microUrl.local,
      responseType: "token",
      urlOpener,
    },
  },
  microfrontends: {
    ...microfrontends_values,
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

export const dev: AppConfigItem = {
  apiUrls: {
    container: "https://dev-racpad.rentacenter.com/api",
    micro: "https://dev-menu-racpad.rentacenter.com/api",
    customers: "https://dev-customers-racpad.rentacenter.com/api",
    agreement: "https://dev-agreement-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.dev,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_8MbOyEYG6",
    userPoolWebClientId: "47r2ihk9fehcpn9t64thdbu2tl",
    oauth: {
      domain: "auth-qa-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.dev,
      redirectSignOut: microUrl.dev,
      responseType: "token",
      urlOpener,
    },
  },
  microfrontends: {
    ...microfrontends_values,
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

export const qa: AppConfigItem = {
  apiUrls: {
    container: "https://qa-racpad.rentacenter.com/api",
    micro: "https://qa-menu-racpad.rentacenter.com/api",
    customers: "https://qa-customers-racpad.rentacenter.com/api",
    agreement: "https://qa-agreement-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.qa,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_8MbOyEYG6",
    userPoolWebClientId: "47r2ihk9fehcpn9t64thdbu2tl",
    oauth: {
      domain: "auth-qa-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.qa,
      redirectSignOut: microUrl.qa,
      responseType: "token",
      urlOpener,
    },
  },
  microfrontends: {
    ...microfrontends_values,
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

export const uat: AppConfigItem = {
  apiUrls: {
    container: "https://uat-racpad.rentacenter.com/api",
    micro: "https://uat-menu-racpad.rentacenter.com/api",
    customers: "https://uat-customers-racpad.rentacenter.com/api",
    agreement: "https://uat-agreement-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.uat,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_FBmCthpda",
    userPoolWebClientId: "1d8vgr67fr7gli9abpv9j1cb3n",
    oauth: {
      domain: "auth-uat-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.uat,
      redirectSignOut: microUrl.uat,
      responseType: "token",
      urlOpener,
    },
  },
  microfrontends: {
    ...microfrontends_values,
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

export const prod: AppConfigItem = {
  apiUrls: {
    container: "https://racpad.rentacenter.com/api",
    micro: "https://racpad-menu.rentacenter.com/api",
    customers: "https://racpad-customers.rentacenter.com/api",
    agreement: "https://racpad-agreement.rentacenter.com/api",
  },
  microUrl: microUrl.prod,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_so5RR57Of",
    userPoolWebClientId: "50nk6ibf5lru47hesk4j0cfuu4",
    oauth: {
      domain: "auth-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.prod,
      redirectSignOut: microUrl.prod,
      responseType: "token",
      urlOpener,
    },
  },
  microfrontends: {
    ...microfrontends_values,
  },
  googleAnalytics: {
    trackingId: ANALYTICS_PROD_ID,
    options: {}
  }
};

export const artifact: AppConfigItem = {
  apiUrls: {
    container: "_apiUrls.container",
    micro: "_apiUrls.micro",
    agreement: "_apiUrls.agreement",
  },
  microUrl: "_microUrl",
  auth: {
    region: "_auth.region",
    userPoolId: "_auth.userPoolId",
    userPoolWebClientId: "_auth.userPoolWebClientId",
    oauth: {
      domain: "_auth.oauth.domain",
      redirectSignIn: "_auth.oauth.redirectSignIn",
      redirectSignOut: "_auth.oauth.redirectSignOut",
      responseType: "_auth.oauth.responseType",
      urlOpener,
    },
  },
  microfrontends: {
    ...microfrontends_values,
  },
  googleAnalytics: {
    trackingId: ANALYTICS_DEV_ID,
    options: {}
  }
};

let appConfigItem: AppConfigItem;

switch (process.env.REACT_APP_STAGE) {
  case "localhost":
    appConfigItem = localhost;
    break;
  case "localhost-ie11":
    appConfigItem = localhostIE11;
    break;
  case "local":
    appConfigItem = local;
    break;
  case "dev":
    appConfigItem = dev;
    break;
  case "qa":
    appConfigItem = qa;
    break;
  case "uat":
    appConfigItem = uat;
    break;
  case "prod":
    appConfigItem = prod;
    break;
  case "artifact":
    appConfigItem = artifact;
    break;
  default:
    appConfigItem = localhost;
    break;
}

export const appConfig: AppConfig = {
  appName: "Menu",
  ...appConfigItem,
};
